import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface AdobeAnalyticsState {
  adobeCloudID: string;
}

export const initialState: AdobeAnalyticsState = {
  adobeCloudID: '',
};

export const adobeAnalyticsSlice = createSlice({
  name: 'adobeAnalytics',
  initialState,
  reducers: {
    setAdobeAnalyticsCloudId: (state, action: PayloadAction<AdobeAnalyticsState['adobeCloudID']>) => {
      state.adobeCloudID = action.payload;
    },
  },
});

export const { setAdobeAnalyticsCloudId } = adobeAnalyticsSlice.actions;

export default adobeAnalyticsSlice.reducer;
