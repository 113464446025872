import type { PreloadedState } from '@reduxjs/toolkit';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import authReducer from '../features/auth/authSlice';
import featureFlagReducer from '../features/featureFlag/featureFlagSlice';
import inviteReducer from '../features/invite/inviteSlice';
import signupReducer from '../features/auth/signup/signupSlice';
import F1ConnectReducer from '../features/onboarding/kyc-retail/f1ConnectSlice';
import paymentsSliceReducer from '../features/payments/paymentsSlice';
import adobeSliceReducer from '../analytics/AdobeAnalytics/adobeAnalyticsSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  signup: signupReducer,
  featureFlag: featureFlagReducer,
  invite: inviteReducer,
  payments: paymentsSliceReducer,
  frankieOneConnect: F1ConnectReducer,
  adobeSlice: adobeSliceReducer,
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch: () => AppDispatch = useDispatch;
