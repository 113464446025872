import { useReducer } from 'react';

export type UseAsyncStateHook<T> = [{ isLoading: boolean; value: T | null }, (value: T | null) => void];

// Always set isLoading to false when a value is set
const reducer = <T>(
  _state: { isLoading: boolean; value: T | null },
  action: T | null = null
): { isLoading: boolean; value: T | null } => ({
  isLoading: false,
  value: action,
});

/**
 * https://docs.expo.dev/router/reference/authentication/
 *
 * A custom hook to load state asynchronously from storage
 *
 * isLoading will be true until the value is loaded
 **/
export const useAsyncState = <T>(
  initialState: { isLoading: boolean; value: T | null } = { isLoading: true, value: null }
): UseAsyncStateHook<T> => {
  return useReducer(reducer, initialState) as UseAsyncStateHook<T>;
};
