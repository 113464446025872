import { useCallback, useEffect } from 'react';
import type { AppStorageKey, BooleanAppStorageKey, NumberAppStorageKey, StringAppStorageKey } from './AppStorage';
import { AppStorage } from './AppStorage';
import type { UseAsyncStateHook } from './useAsyncState';
import { useAsyncState } from './useAsyncState';

// Same as UseAsyncStateHook, except the setState fn is a promise.
export type UseAppStorageHook<T> = [UseAsyncStateHook<T>[0], (value: T | null) => Promise<void>];

/**
 * A custom hook to load and save state from local storage (web) or Expo SecureStore (mobile)
 *
 * The value will be retrieved asynchronously. isLoading will be initially true, until the value has been loaded.
 *
 * Pass _unsafeMobileUseAsyncStorage as true to use mobile AsyncStorage (unsecure) instead.
 *
 * **Sensitive values should not be stored in web or in mobile when _unsafeMobileUseAsyncStorage is true**
 */
export function useAppStorage(
  key: StringAppStorageKey,
  _unsafeMobileUseAsyncStorage?: boolean
): UseAppStorageHook<string>;
export function useAppStorage(
  key: NumberAppStorageKey,
  _unsafeMobileUseAsyncStorage?: boolean
): UseAppStorageHook<number>;
export function useAppStorage(
  key: BooleanAppStorageKey,
  _unsafeMobileUseAsyncStorage?: boolean
): UseAppStorageHook<boolean>;
export function useAppStorage<T extends string | number | boolean>(
  key: AppStorageKey,
  _unsafeMobileUseAsyncStorage?: boolean
): UseAppStorageHook<T> {
  const [state, setState] = useAsyncState<T>();

  // Initialise - get value from storage
  useEffect(() => {
    void (async () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument -- as any needed for function overloads to work
      const value = (await AppStorage.getItem(key as any, _unsafeMobileUseAsyncStorage)) as T | null;
      setState(value);
    })();
  }, [_unsafeMobileUseAsyncStorage, key, setState]);

  // Set state value and update storage
  const setValue = useCallback(
    async (value: T | null) => {
      setState(value);
      // Update persistent storage as well
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument -- as any needed for function overloads to work
      await AppStorage.setItem(key as any, value as any, _unsafeMobileUseAsyncStorage);
    },
    [_unsafeMobileUseAsyncStorage, key, setState]
  );

  return [state, setValue];
}
