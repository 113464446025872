import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from 'expo-secure-store';
import { Platform } from 'react-native';

/**
 * Add any new key to two places - WHAT the type is & WHERE it will be used below
 *
 * Keys of items stored in persistent app storage. Add more keys as needed.
 *
 * installed - A flag to determine if the app has been previously installed. Will exist only in mobile, both in secure and unsecure storage
 *
 * customerNumber - The customer number of the last authenticated user
 *
 * deviceId - A random unique identifier for the device
 *
 * pin - The user's pin used to login instead of password or biometrics
 *
 * pinAttempts - The number of failed pin attempts made by the user. First failed attempt is 1, second is 2, etc.
 *
 * biometricsEnabled - The user's preference to use biometrics for login
 */

// WHAT AppStorageKey types are. All values can be also null.
const StringAppStorageKeys = ['customerNumber', 'deviceId', 'pin'] as const;
const NumberAppStorageKeys = ['pinAttempts'] as const;
const BooleanAppStorageKeys = ['installed', 'biometricsEnabled'] as const;

// Keys MUST be unique across the above arrays.
if (
  StringAppStorageKeys.length + NumberAppStorageKeys.length + BooleanAppStorageKeys.length !==
  new Set([...StringAppStorageKeys, ...NumberAppStorageKeys, ...BooleanAppStorageKeys]).size
) {
  throw new Error('Duplicate keys found in AppStorageKeys');
}

export type StringAppStorageKey = (typeof StringAppStorageKeys)[number];
export type NumberAppStorageKey = (typeof NumberAppStorageKeys)[number];
export type BooleanAppStorageKey = (typeof BooleanAppStorageKeys)[number];
export type AppStorageKey = StringAppStorageKey | NumberAppStorageKey | BooleanAppStorageKey;

// WHERE AppStorageKey values are stored for each app client. If the key doesn't exist in this array, both set and get item will do nothing / return null.
export const WebAppStorageKeys: AppStorageKey[] = ['deviceId'];
export const MobileAppStorageKeys: AppStorageKey[] = [
  'installed',
  'customerNumber',
  'deviceId',
  'pin',
  'pinAttempts',
  'biometricsEnabled',
];
export const MobileAppUnsecureStorageKeys: AppStorageKey[] = ['installed'];

const getItemToType = (key: AppStorageKey, value: string | null): string | number | boolean | null => {
  if (value === null) {
    return null;
  }
  if (StringAppStorageKeys.includes(key as StringAppStorageKey)) {
    return value;
  } else if (NumberAppStorageKeys.includes(key as NumberAppStorageKey)) {
    const parsed = parseInt(value, 10);
    return isNaN(parsed) ? null : parsed;
  } else if (BooleanAppStorageKeys.includes(key as BooleanAppStorageKey)) {
    return value === 'true';
  }
  return null;
};

// Function overloads so the type is as needed
async function getItem(key: StringAppStorageKey, _unsafeMobileUseAsyncStorage?: boolean): Promise<string | null>;
async function getItem(key: NumberAppStorageKey, _unsafeMobileUseAsyncStorage?: boolean): Promise<number | null>;
async function getItem(key: BooleanAppStorageKey, _unsafeMobileUseAsyncStorage?: boolean): Promise<boolean | null>;
async function getItem(
  key: AppStorageKey,
  _unsafeMobileUseAsyncStorage?: boolean
): Promise<string | number | boolean | null> {
  // Do nothing if the key is not allowed for the current platform.
  if (Platform.OS === 'web' && !WebAppStorageKeys.includes(key)) {
    return null;
  } else if (Platform.OS !== 'web') {
    if (_unsafeMobileUseAsyncStorage && !MobileAppUnsecureStorageKeys.includes(key)) {
      return null;
    } else if (!_unsafeMobileUseAsyncStorage && !MobileAppStorageKeys.includes(key)) {
      return null;
    }
  }
  try {
    if (Platform.OS === 'web') {
      if (typeof localStorage !== 'undefined') {
        const value = localStorage.getItem(key);
        return getItemToType(key, value);
      }
      return null;
    }
    if (_unsafeMobileUseAsyncStorage) {
      const value = await AsyncStorage.getItem(key);
      return getItemToType(key, value);
    }
    const value = await SecureStore.getItemAsync(key);
    return getItemToType(key, value);
  } catch (e) {
    return null;
  }
}

// Function overloads so the type is as needed
async function setItem(
  key: StringAppStorageKey,
  value: string | null,
  _unsafeMobileUseAsyncStorage?: boolean
): Promise<void>;
async function setItem(
  key: NumberAppStorageKey,
  value: number | null,
  _unsafeMobileUseAsyncStorage?: boolean
): Promise<void>;
async function setItem(
  key: BooleanAppStorageKey,
  value: boolean | null,
  _unsafeMobileUseAsyncStorage?: boolean
): Promise<void>;
async function setItem(
  key: AppStorageKey,
  value: string | number | boolean | null,
  _unsafeMobileUseAsyncStorage?: boolean
): Promise<void> {
  // Do nothing if the key is not allowed
  if (Platform.OS === 'web' && !WebAppStorageKeys.includes(key)) {
    return;
  } else if (Platform.OS !== 'web') {
    if (_unsafeMobileUseAsyncStorage && !MobileAppUnsecureStorageKeys.includes(key)) {
      return;
    } else if (!_unsafeMobileUseAsyncStorage && !MobileAppStorageKeys.includes(key)) {
      return;
    }
  }
  if (Platform.OS === 'web') {
    try {
      if (value === null) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, value.toString());
      }
    } catch {
      // Do nothing, local storage is disabled or unavailable
    }
  } else if (value === null) {
    if (_unsafeMobileUseAsyncStorage) {
      await AsyncStorage.removeItem(key);
    } else {
      await SecureStore.deleteItemAsync(key);
    }
  } else if (_unsafeMobileUseAsyncStorage) {
    await AsyncStorage.setItem(key, value.toString());
  } else {
    await SecureStore.setItemAsync(key, value.toString());
  }
}

/**
 * Get and store items in persistent device storage. Web will use local storage (unsecure), mobile will use Expo Secure Store (secure) or AsyncStorage (unsecure)
 *
 * Recommended to use the hook `useAppStorage` for usage within any React components.
 */
export const AppStorage = {
  /**
   * Retrieves a value from persistent storage
   *
   * For web, local storage is used (unsecure)
   *
   * For mobile, Expo SecureStore is used (secure). SecureStore is persistent after uninstall for iOS (removed for Android).
   *
   * Pass _unsafeMobileUseAsyncStorage as true to use AsyncStorage (unsecure) instead. AsyncStorage is removed after uninstall for both Android and iOS.
   */
  getItem,
  /**
   * Stores a value in persistent storage
   *
   * For web, local storage is used (unsecure)
   *
   * For mobile, Expo SecureStore is used (secure). SecureStore is persistent after uninstall for iOS (removed for Android).
   *
   * Pass `_unsafeMobileUseAsyncStorage` as true to use AsyncStorage (unsecure) instead only if needed. AsyncStorage is removed after uninstall for both Android and iOS.
   *
   * If you use `_unsafeMobileUseAsyncStorage` to store a value, it can only be retrieved by also passing the same flag to `getItem`
   *
   */
  setItem,
};
